@font-face {
    font-family: "Jost-Medium";
    src: local("Jost-Medium"),
        url("./assets/fonts/Jost-Medium.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Jost-Bold";
    src: local("Jost-Bold"),
        url("./assets/fonts/Jost-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Jost-SemiBold";
    src: local("Jost-SemiBold"),
        url("./assets/fonts/Jost-SemiBold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Jost-Light";
    src: local("Jost-Light"),
        url("./assets/fonts/Jost-Light.ttf") format("truetype");
    font-weight: bold;
}

.font-jost-medium {
    font-family: "Jost-Medium";
}

.font-jost-bold {
    font-family: "Jost-Bold";
}

.font-jost-semibold {
    font-family: "Jost-SemiBold";
}

.font-jost-light {
    font-family: "Jost-Light";
}

.bg-longrich-orange {
    background: #ff6249;
}

.bg-longrich-blue {
    background: #25284b
}

.text-longrich-blue {
    color: #25284b
}

.text-longrich-orange {
    color: #ff6249;
}

.w-60vw {
    width: 60vw;
}

.w-products {
    width: 60vw;
}

@media screen and (max-width: 768px) {
    .w-products {
        width: 100vw;
    }
}


.w-50vw {
    width: 50vw;
}

.w-15vw {
    width: 15vw;
}


.thumbs-wrapper {
    display: none;
}

.report-container {
    height: 100%;
    width: 100%;
}

.report-container iframe {
    width: 100%;
    height: 100%;
}

html,
body,
#root {
    height: 100%;
}

.min-width-80 {
    min-width: 80px;
}

.min-width-150 {
    min-width: 150px;
}

.greyOpaqueBackground {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.salesRankingTableWidth {
    width: 600px;
}

.campaignSidebarAward {
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 40%; /* Adjust this value to move the positioned div up and down */
    background: rgba(0, 0, 0, 0.8);
    font-family: Arial, sans-serif;
    color: #fff;
    width: 60%; /* Set the width of the positioned div */
}

.rbc-event {
    background-image: linear-gradient(
        to right,
        rgba(197 144 115),
        rgba(255 253 225),
        rgba(218 147 88),
        rgba(255 252 213),
        rgba(231 184 134)
    );
    color: #362f78;
    border: 1px solid black;
    font-size: 0.875rem;
    margin: 0.1rem;
    text-transform: capitalize;
}

.rbc-header {
    background-image: linear-gradient(to right, #f1cd5d, #fae371, #e8b94f);
    color: black;
    text-align: center;
    padding: 10px 0;
    border-left: 1px solid black !important;
    border-bottom: 1px solid black !important;
}

.rbc-toolbar {
    font-weight: 600;
    background-color: black;
    color: #eaf3f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-bottom: 0px;
    font-size: 20px;
}

.rbc-off-range-bg {
    background-color: rgba(53, 70, 80, 0.2);
}

.rbc-btn-group {
    background: white;
    font-size: 1rem;
}

.rbc-active {
    background-image: linear-gradient(
        to right,
        #bf953f,
        #b38728,
        #aa771c
    ) !important;
    color: white !important;
}

.banner-image {
    width: initial !important;
    height: 90vh !important;
}

.small-banner-image {
    width: initial !important;
    height: 40vh !important;
}

.default-image {
    width: initial !important;
    height: initial !important;
}

.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
    background-color: transparent;
    right: 0.4rem;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    left: 0.4rem;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: "";
    border: solid rgb(226, 232, 240);
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 12px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: "";
    border: solid rgb(226, 232, 240);
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 12px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.control-dots {
    width: 50% !important;
}

.carousel > button {
    height: 100vh !important;
}

.custom-min-height {
    min-height: 40px;
}

.slider-background {
    background: url(./assets/images/sliderbackground.jpg);
    background-position: center;
    background-size: cover;
}

.tnc-background {
    background: url(./assets/images/tncbg.png);
    background-position: center;
    background-size: cover;
}

.footer-background {
    background: url("https://ik.imagekit.io/dnddecpho/longrich/DD_ImagesForDEv13_xl8cnh-vF.png?updatedAt=1713795258239");
    background-position: center;
    background-size: cover;
}

.airline-background {
    background: url(./assets/images/airline.webp);
    background-position: center;
    background-size: cover;
}

.long-text {
    max-width: 20rem; /* Adjust as needed */
    overflow-wrap: break-word;
}

@media (max-width: 770px) {
    .control-dots {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .rbc-btn-group {
        display: none;
    }

    .control-dots {
        width: 100% !important;
    }

    .banner-image {
        height: calc(100vh - 48px) !important;
    }
}

.title {
    margin: 0;
}

.subtitle {
    max-width: 576px;
    margin: 36px auto;
    letter-spacing: 0.1em;
    line-height: 1.3em;
}

.countdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 -8px 0 -8px;
    background: black;
}

.time-section {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white !important;
}

.time {
    margin: 0;
    font-size: 60px !important;
    font-weight: 800 !important;
}

.small {
    font-size: 18px !important;
}

.max-h-small {
    max-height: 30vh !important;
}

.max-h-base {
    max-height: 55vh !important;
}

.h-35vh {
    height: 35vh!important;
}

.h-65vh {
    height: 65vh!important;
}

.w-100px{
    width:100px!important;
}

.w-10vw{
    width:10vw!important;
}

.w-20vw{
    width:20vw!important;
}

.w-128 {
    width: 32rem!important;
}


/* Hide non-date fields on mobile */
@media (max-width: 768px) {
    .hidden-mobile {
      display: none;
    }
  }
  
